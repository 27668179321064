exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-edycja-stron-index-js": () => import("./../../../src/pages/admin/edycja-stron/index.js" /* webpackChunkName: "component---src-pages-admin-edycja-stron-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-zapytania-js": () => import("./../../../src/pages/admin/zapytania.js" /* webpackChunkName: "component---src-pages-admin-zapytania-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-panel-klienta-js": () => import("./../../../src/pages/panel-klienta.js" /* webpackChunkName: "component---src-pages-panel-klienta-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-uslugi-biuro-rachunkowe-js": () => import("./../../../src/pages/uslugi/biuro-rachunkowe.js" /* webpackChunkName: "component---src-pages-uslugi-biuro-rachunkowe-js" */),
  "component---src-pages-uslugi-finanse-js": () => import("./../../../src/pages/uslugi/finanse.js" /* webpackChunkName: "component---src-pages-uslugi-finanse-js" */),
  "component---src-pages-uslugi-media-js": () => import("./../../../src/pages/uslugi/media.js" /* webpackChunkName: "component---src-pages-uslugi-media-js" */),
  "component---src-pages-uslugi-ubezpieczenia-js": () => import("./../../../src/pages/uslugi/ubezpieczenia.js" /* webpackChunkName: "component---src-pages-uslugi-ubezpieczenia-js" */)
}

